import { template as template_b766dd21ad7041e095dde3dd71fd05f0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b766dd21ad7041e095dde3dd71fd05f0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

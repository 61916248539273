import { template as template_3587af67b36e433da5411b221d47c9b0 } from "@ember/template-compiler";
const FKLabel = template_3587af67b36e433da5411b221d47c9b0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_24eadfc7db9a4a55bbe66388481b1353 } from "@ember/template-compiler";
const FKControlMenuContainer = template_24eadfc7db9a4a55bbe66388481b1353(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

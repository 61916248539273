import { template as template_a99a9bbcc6344203940b32bd7ad94006 } from "@ember/template-compiler";
const WelcomeHeader = template_a99a9bbcc6344203940b32bd7ad94006(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
